const lang = {
    en: {
        DARK_MODE: `DarkMode`,
    },
    de: {
        DARK_MODE: `NachtModus`,
    },
}

module.exports = lang
